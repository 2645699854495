import {useQueries} from '@tanstack/react-query';
import {useIntegrationApp} from '@integration-app/react';
import {useConnections} from './useConnections';
import {IDrive} from 'spekit-types';
import {logging} from 'spekit-datalayer';

export const useDrives = (hasContentIntegrationSharepointFlag?: boolean) => {
  const integrationApp = useIntegrationApp();

  const {connections, stores} = useConnections(hasContentIntegrationSharepointFlag);

  const drives = useQueries({
    queries: stores.map((store) => {
      return {
        retry: false,
        queryKey: ['drives', store.connectionKey],
        queryFn: async () => {
          const response = await integrationApp
            .connection(store.connectionKey)
            .action('list-drives')
            .run();
          return response.output.records.map((result: any) => result.fields) as IDrive[];
        },
        enabled: Boolean(
          connections[store.connectionKey] &&
            !connections[store.connectionKey].disconnected
        ),
        onError: logging.capture,
      };
    }),
  });

  return drives.map((drive, index) => ({
    ...drive,
    store: stores[index],
    connection: connections[stores[index].connectionKey],
  }));
};
